<template>
  <div>
  <el-dialog
    :title="propTitle"
    :visible.sync="dialogVisible"
    width="400px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="80px"
      >
      <el-form-item label="讲师人员" prop="userName">
        <div class="select-box-info">
          <el-tag
            v-for="(tag,index) in teachParams"
            :key="index"
            closable
            :disable-transitions="false"
            @close="_handleTagsClose(tag,index)">
            {{tag.employeeName}}
          </el-tag>
          <el-tag effect="dark" @click="personnelShow = true"><i class="el-icon-plus"></i>员工</el-tag>
      </div>
      </el-form-item>
      <el-form-item label="所属团队">
        <el-select filterable value-key="id" clearable v-model="ruleForm.lecturerTeamId" placeholder="请选择" style="width: 275px">
            <el-option
              v-for="item in lectureTeamList"
              :key="item.id"
              :label="item.lecturerTeam"
              :value="item.id"
            />
          </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="_submit()">确 定</el-button>
    </span>
  </el-dialog>
    <!-- 选择人员 -->
    <SelectPersonnel
      v-if="personnelShow"
      :personnelShow="personnelShow"
      @submitHandle="_submitHandle"
      type="lecturerSetting"
      title="选择人员"
    />
  </div>
</template>

<script>
import { getManageFrameDeptApi } from '@/api/api'
import { getTeachUserAddOrEditApi, getTeachTeamListApi } from '@/api/settings'
import SelectPersonnel from '@/components/selectPersonnel/index'
export default {
  components: {
    SelectPersonnel
  },
  props: {
    propTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialogVisible: false,
      rules: {
        lecturerTeamId: [
          { required: true, message: '请输入名称', trigger: ['change', 'blur'] }
        ],
        useType: [
          { required: true, message: '请选择可见范围', trigger: ['change', 'blur'] }
        ]
      },
      lectureTeamList: [], // 所属团队列表
      personnelShow: false,
      teachTags: [],
      ruleForm: {
        lecturerTeamId: '', // 讲师团队ID
        employeeId: '', // 员工id
        employeeName: '', // 员工name
        deptId: '',
        deptName: '',
        remark: '', // 备注
        id: undefined
      },
      teachParams: []
    }
  },
  created () {
    this.getTeachTeamList()
    this.getDeptList()
  },
  methods: {
    _handleTagsClose (tag, index) {
      this.teachParams.splice(index, 1)
    },
    // 获取讲师团队列表
    getTeachTeamList () {
      getTeachTeamListApi().then((res) => {
        if (res.code === 200) {
          this.lectureTeamList = res.data
        }
      })
    },
    show (row) {
      this.getTeachTeamList()
      if (row) {
        this.ruleForm = row
      }
      this.dialogVisible = true
      this.teachParams = []
    },
    handleClose () {
      this.ruleForm = {}
      this.dialogVisible = false
    },
    getDeptList () {
      getManageFrameDeptApi().then(res => {
        if (res.code === 200) {
          this.visibleDeptData = JSON.parse(JSON.stringify(res.data))
        }
      })
    },
    _submitHandle (arr) {
      console.log(arr, 'arr')
      const newArr = arr.map((item) => {
        return {
          employeeId: item.userId, // 员工id
          employeeName: item.name, // 员工name
          deptId: item.parentId,
          deptName: item.parentName,
          id: undefined
        }
      })
      this.teachParams.push.apply(this.teachParams, newArr)
      this.teachParams = this._deduplicate(this.teachParams)
      this.personnelShow = false
    },
    // 数组对象去重
    _deduplicate (arr) {
      const map = new Map()
      for (const item of arr) {
        if (!map.has(item.employeeId)) {
          map.set(item.employeeId, item)
        }
      }
      return [...map.values()]
    },
    _submit () {
      this.teachParams = this.teachParams.map((item) => {
        return {
          ...item,
          lecturerTeamId: this.ruleForm.lecturerTeamId, // 讲师团队ID
          remark: this.ruleForm.remark // 备注
        }
      })
      getTeachUserAddOrEditApi(this.teachParams).then(res => {
        if (res.code === 200) {
          this.$parent._getRightTeacherList()
          this.dialogVisible = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.select-box-info{
  .el-tag{
    width: 78px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    margin-right: 12px;
  }
}
</style>
